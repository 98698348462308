import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';

interface Props {
  asyncCallback: (value: any) => any; // These callback functions are used to fetch data from the server and can be of any type
  asyncGet?: (value: any) => any;
  allSearchParams: Record<string, any>;
  fieldName: string;
  label: string;
  getOptionLabel: (item: any) => string;
  getOptionValue?: (item: any) => string;
  limitTags?: number;
  sx?: SxProps<Theme>;
  defaultValues?: Array<any>;
  multiple?: boolean;
  onChange?: (value: string | null) => void;
}

const TypeaheadSelector = ({
  asyncCallback,
  asyncGet,
  allSearchParams,
  fieldName,
  label,
  getOptionLabel,
  getOptionValue = (item: any) => item.id || '',
  limitTags = 1,
  sx = {},
  defaultValues = [],
  multiple = true,
  onChange,
}: Props) => {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [fieldName]: defaultValues as Array<any>,
    },
  });

  const value = watch(fieldName);

  useEffect(() => {
    setValueFromSearchParamsOnMount();
  }, []);

  const handleSelectionChange = (_event: React.SyntheticEvent, value: string) => {
    if (onChange && value) {
      onChange(value);
    }
  };

  useEffect(() => {
    updateSearchParams();
  }, [value]);

  const determinePropertyName = (getOptionValue: (item: any) => string): string => {
    let accessedProperty: string | undefined;

    // Create a proxy to intercept property access
    const proxy = new Proxy(
      {},
      {
        get: (target, property) => {
          accessedProperty = property as string;
          return 'mock_value';
        },
      },
    );

    getOptionValue(proxy);

    return accessedProperty || 'id';
  };

  const createCompatibleObject = (
    value: string,
    getOptionValue: (item: any) => string,
  ) => {
    const propertyName = determinePropertyName(getOptionValue);
    return { [propertyName]: value };
  };

  const setValueFromSearchParamsOnMount = async () => {
    const value = allSearchParams[fieldName] ? allSearchParams[fieldName].split('|') : [];
    if (value.length === 0) return;

    const promises = value.map(async (value: string) =>
      asyncGet ? await asyncGet(value) : createCompatibleObject(value, getOptionValue),
    );
    const p = await Promise.all(promises);
    if (p.length > 0) {
      setValue(fieldName, p);
      trigger(fieldName);
    }
  };

  const updateSearchParams = async () => {
    const params = new URLSearchParams(allSearchParams);
    if (value && value.length) {
      const ids = value.map(getOptionValue).join('|');
      params.set(fieldName, ids);
    } else if (params.has(fieldName)) {
      params.delete(fieldName);
    }

    if (params.get(fieldName) !== currentSearchParams.get(fieldName)) {
      setSearchParams(params);
      setValue(fieldName, value); // Does not cause a loop because only updates if the param value has changed
    }
  };

  return (
    <>
      <AutocompleteAsyncFormField
        control={control}
        name={fieldName}
        errors={errors}
        asyncCallback={asyncCallback}
        hideLabel
        inputProps={{
          placeholder: value && value.length ? '' : label,
          sx: {
            position: value && value.length ? 'absolute' : null,
            right: 0,
            flexDirection: 'row',
          },
        }}
        getValue={getOptionValue}
        getLabel={getOptionLabel}
        multiple={multiple}
        limitTags={limitTags}
        sx={{ ...sx }}
        onInput={handleSelectionChange}
        hideChipOverflow
      />
    </>
  );
};

export { TypeaheadSelector };
