import { Account_Read as AccountProto, AccountType } from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Account } from '~hooks/useAccount';
import connection from '~services/connectionModule';

export interface CreateConnectedAccountsByCompanyIdsParams {
  receiverCompanyId: string;
  senderCompanyId: string;
  accountTypes: AccountType[];
  callBack?: (account: Account) => void;
}

export const useAccountsAdmin = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const createConnectedAccountByCompanyIds = async ({
    senderCompanyId,
    receiverCompanyId,
    accountTypes,
    callBack,
  }: CreateConnectedAccountsByCompanyIdsParams) => {
    setIsUpdating(true);
    return connection
      .post<AccountProto>(`${API_VERSION}/accounts/connected`, {
        sender_company_id: senderCompanyId,
        receiver_company_id: receiverCompanyId,
        sender_account_types: accountTypes,
      })
      .then((resp: AccountProto): Account => {
        // Update the type of resp to Account_Read
        const accountSaved = Account.parse(resp);
        callBack?.(accountSaved);
        return accountSaved;
      })
      .catch((err) => ({ error: err }))
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return {
    isUpdating,
    createConnectedAccountByCompanyIds,
  } as const;
};
