import { AgaveLinkedAccount_Read, AgaveLinkToken_Read } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';

export default function useAgave() {
  const createLinkedAccount = async (publicToken: string) => {
    try {
      const response = await connection.post<AgaveLinkedAccount_Read>(
        `${API_VERSION}/users/me/agave/linked_accounts`,
        { public_token: publicToken },
        {},
        t('error_messages.agave.failed_to_create_linked_account') as string,
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getLinkToken = async () => {
    try {
      const response = await connection.post<AgaveLinkToken_Read>(
        `${API_VERSION}/users/me/agave/link_tokens`,
        {},
        {},
        t('error_messages.agave.failed_to_fetch_link_token') as string,
      );

      return response.link_token;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getLinkedAccounts = async () => {
    try {
      const response = await connection.getPaginated<AgaveLinkedAccount_Read>(
        `${API_VERSION}/users/me/agave/linked_accounts`,
        {},
        t('error_messages.agave.failed_to_fetch_linked_accounts') as string,
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { createLinkedAccount, getLinkToken, getLinkedAccounts };
}
