import { t as $t } from 'i18next';
import { useEffect, useState } from 'react';

import connection from '~services/connectionModule';

interface VersionData {
  sha: string;
  time: string;
}

export const useVersion = () => {
  const [version, setVersion] = useState({ sha: '', time: '' });

  useEffect(() => {
    const loadVersion = async () => {
      await getVersion();
    };
    loadVersion();
  }, []);

  const getVersion = async () => {
    return connection
      .get<VersionData>(
        '/version',
        {},
        $t('error_messages.version.failed_to_fetch') as string,
      )
      .then((resp) => {
        setVersion(resp);
      })
      .catch((error) => {
        console.error('Error fetching version info:', error);
      });
  };

  return {
    version,
    getVersion,
  };
};
