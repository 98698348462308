import {
  OrderUnitOfMeasure,
  SettlementLineItem_Group_Read,
  SettlementLineItem_Read,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

import { Nullable } from '~types/Nullable';

class SettlementLineItemQuantity {
  constructor(
    private _quantity: number,
    private _unit_of_measure: OrderUnitOfMeasure,
  ) {}

  public get quantity() {
    return this._quantity;
  }

  public get unitOfMeasure() {
    return this._unit_of_measure;
  }

  public static parse(proto: SettlementLineItem_Read['quantities'][number]) {
    return new SettlementLineItemQuantity(Number(proto.quantity), proto.unit_of_measure);
  }
}

class SettlementLineItemIds {
  constructor(
    private _order_id: string,
    private _job_id: string,
    private _friendly_job_id: string,
    private _friendly_order_id: string,
  ) {}

  public get orderId() {
    return this._order_id;
  }

  public get jobId() {
    return this._job_id;
  }

  public get friendlyJobId() {
    return this._friendly_job_id;
  }

  public get friendlyOrderId() {
    return this._friendly_order_id;
  }

  public static parse(proto: SettlementLineItem_Read['ids'][number]) {
    return new SettlementLineItemIds(
      proto.order_id,
      proto.job_id,
      proto.friendly_job_id,
      proto.friendly_order_id,
    );
  }
}

export class SettlementLineItem {
  constructor(
    private _date: Dayjs,
    private _truck_external_ids: string[],
    private _equipment_types: string[],
    private _services: string[],
    private _materials: string[],
    private _quantities: SettlementLineItemQuantity[],
    private _ids: SettlementLineItemIds[],
    private _total_quantity: number,
    private _rate_value: number,
    private _rate_types: string[],
    private _total_amount: number,
    private _loads_count: number,
    private _ticket_numbers: Nullable<string[]>,
    private _pay_start_time: Nullable<Dayjs>,
    private _pay_end_time: Nullable<Dayjs>,
    private _billing_adjustment_minutes: Nullable<number>,
  ) {}

  public get date() {
    return this._date;
  }

  public get truckExternalIds() {
    return this._truck_external_ids;
  }

  public get equipmentTypes() {
    return this._equipment_types;
  }

  public get services() {
    return this._services;
  }

  public get materials() {
    return this._materials;
  }

  public get quantities() {
    return this._quantities;
  }

  public get ids() {
    return this._ids;
  }

  public get totalQuantity() {
    return this._total_quantity;
  }

  public get rateValue() {
    return this._rate_value;
  }

  public get rateTypes() {
    return this._rate_types;
  }

  public get totalAmount() {
    return this._total_amount;
  }

  public get loadsCount() {
    return this._loads_count;
  }

  public get ticketNumbers() {
    return this._ticket_numbers;
  }

  public get payStartTime() {
    return this._pay_start_time;
  }

  public get payEndTime() {
    return this._pay_end_time;
  }

  public get billingAdjustmentMinutes() {
    return this._billing_adjustment_minutes;
  }

  public static parse(proto: SettlementLineItem_Read) {
    return new SettlementLineItem(
      dayjs(proto.date),
      proto.truck_external_ids,
      proto.equipment_types,
      proto.services,
      proto.materials,
      proto.quantities.map((quantity) => SettlementLineItemQuantity.parse(quantity)),
      proto.ids.map((id) => SettlementLineItemIds.parse(id)),
      Number(proto.total_quantity),
      Number(proto.rate_value),
      proto.rate_types,
      Number(proto.total_amount),
      Number(proto.loads_count),
      proto.ticket_numbers ?? null,
      _.isNil(proto.pay_start_time) ? null : dayjs(proto.pay_start_time),
      _.isNil(proto.pay_end_time) ? null : dayjs(proto.pay_end_time),
      _.isNil(proto.billing_adjustment_minutes)
        ? null
        : Number(proto.billing_adjustment_minutes),
    );
  }
}

export class SettlementLineItemGroup {
  constructor(
    private _group: Nullable<string>,
    private _line_items: SettlementLineItem[],
    private _subtotal: Nullable<number>,
  ) {}

  public get group() {
    return this._group;
  }

  public get lineItems() {
    return this._line_items;
  }

  public get subtotal() {
    return this._subtotal;
  }

  public static parse(proto: SettlementLineItem_Group_Read) {
    return new SettlementLineItemGroup(
      proto.group ?? null,
      proto.line_items.map((lineItem) => SettlementLineItem.parse(lineItem)),
      _.isNil(proto.subtotal) ? null : Number(proto.subtotal),
    );
  }
}
