import { Dayjs } from 'dayjs';
import { makeObservable, observable, runInAction } from 'mobx';

import type { DriverDay } from '~hooks/useDriverDays/models';
import type { Job, JobSummary } from '~hooks/useJob';
import { Pagination, PaginationLink } from '~interfaces';
import { getPageNumber } from '~utils/pagination';

type DriverDayPageFilters = {
  customerAccountIds?: string[];
  driverIds?: string[];
  vendorAccountIds?: string[];
};

export type DriverDayPageFilterKey = keyof DriverDayPageFilters;

type DriverDayPageDateFilters = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

export default class DriverDayStore {
  dateFilters: DriverDayPageDateFilters = {
    startDate: null,
    endDate: null,
  };
  driverDays: DriverDay[] = [];
  filters: DriverDayPageFilters = {};
  jobIdsByDriverDayId: Record<string, string[]> = {};
  jobByJobId: Record<string, Job> = {};
  jobSummaryByJobId: Record<string, JobSummary> = {};
  pagination: Pagination = {
    limit: 25,
    after: '',
    before: '',
    page: 1,
  };

  constructor() {
    makeObservable(this, {
      dateFilters: observable,
      driverDays: observable,
      filters: observable,
      jobIdsByDriverDayId: observable,
      jobByJobId: observable,
      jobSummaryByJobId: observable,
      pagination: observable,
    });
  }

  setDateFilters(startDate: Dayjs | null, endDate: Dayjs | null) {
    runInAction(() => {
      this.dateFilters = {
        startDate,
        endDate,
      };
    });
  }

  setDriverDays(driverDays: DriverDay[]) {
    runInAction(() => {
      this.driverDays = driverDays;
    });
  }

  updateDriverDay(driverDay: DriverDay) {
    runInAction(() => {
      const index = this.driverDays.findIndex((dDay) => dDay.id === driverDay.id);
      this.driverDays = [
        ...this.driverDays.slice(0, index),
        driverDay,
        ...this.driverDays.slice(index + 1),
      ];
    });
  }

  updateFilter(key: DriverDayPageFilterKey, value: string[] | undefined) {
    runInAction(() => {
      this.filters[key] = value;
    });
  }

  resetFilters() {
    runInAction(() => {
      this.filters = {};
    });
  }

  setJobIdsByDriverDayId(driverDayId: string, jobIds: string[]) {
    runInAction(() => {
      this.jobIdsByDriverDayId[driverDayId] = jobIds;
    });
  }

  setJobByJobId(jobId: string, job: Job) {
    runInAction(() => {
      this.jobByJobId[jobId] = job;
    });
  }

  setJobs(jobs: Job[]) {
    runInAction(() => {
      jobs.forEach((job) => {
        this.jobByJobId[job.id] = job;
      });
    });
  }

  setJobSummaryByJobId(jobId: string, jobs: JobSummary) {
    runInAction(() => {
      this.jobSummaryByJobId[jobId] = jobs;
    });
  }

  setPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }
}
