import { createTheme } from '@mui/material/styles';
// Keep  in order to support Mui DataGrid theme customization
import {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { CSSProperties } from 'react'; // Define custom theme values

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle1Medium: CSSProperties;
    subtitle1SemiBold: CSSProperties;
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle1Medium?: CSSProperties;
    subtitle1SemiBold?: CSSProperties;
  }

  interface Theme {
    brandV2: {
      borderRadius: string;
      colors: {
        treadBlack: string;
        treadBlue: string;
        treadGray1: string;
        treadGray2: string;
        treadGray3: string;
        treadGray4: string;
        treadGray6: string;
        treadGray7: string;
        treadGray8: string;
        treadGray9: string;
        treadGreen: string;
        treadOrange: string;
        treadOrangeDark: string;
        treadRed: string;
        treadYellow: string;
        treadYellowDark: string;
      };
    };
  }

  interface ThemeOptions {
    brandV2?: {
      borderRadius?: string;
      colors?: {
        treadBlack?: string;
        treadBlue?: string;
        treadGray1?: string;
        treadGray2?: string;
        treadGray3?: string;
        treadGray4?: string;
        treadGray6?: string;
        treadGray7?: string;
        treadGray8?: string;
        treadGray9?: string;
        treadGreen?: string;
        treadOrange?: string;
        treadOrangeDark?: string;
        treadRed?: string;
        treadYellow?: string;
        treadYellowDark?: string;
      };
    };
  }

  interface Palette {
    brandV2Black: Palette['primary'];
    brandV2Green: Palette['primary'];
    brandV2Orange: Palette['primary'];
    brandV2Red: Palette['primary'];
    brandV2TreadGray3: Palette['primary'];
    brandV2Yellow: Palette['primary'];
  }

  interface PaletteOptions {
    brandV2Black?: PaletteOptions['primary'];
    brandV2Green?: PaletteOptions['primary'];
    brandV2Orange?: PaletteOptions['primary'];
    brandV2Red?: PaletteOptions['primary'];
    brandV2TreadGray3?: PaletteOptions['primary'];
    brandV2Yellow?: PaletteOptions['primary'];
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle1Medium: true;
    subtitle1SemiBold: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    brandV2Black: true;
    brandV2Green: true;
    brandV2Orange: true;
    brandV2Red: true;
    brandV2TreadGray3: true;
    brandV2Yellow: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#F6CC46',
      dark: '#FABD06',
      light: '#FFFAED',
      contrastText: '#1C1C1C',
    },
    secondary: {
      main: '#252135',
      dark: '#161032',
      light: '#F9F9F9',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
      contrastText: '#FFFFFF',
    },
    divider: '#EAECF0',
    action: {
      active: 'rgba(0, 0, 0, 0.56)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    brandV2Black: {
      main: '#132732',
      light: '#42525B',
      dark: '#0D1B23',
      contrastText: '#FFFFFF',
    },
    brandV2Green: {
      main: '#00A11A',
      light: '#33B347',
      dark: '#007012',
      contrastText: '#FFFFFF',
    },
    brandV2Orange: {
      main: '#FFAA13',
      light: '#FFBB42',
      dark: '#FF7A00',
      contrastText: '#132732',
    },
    brandV2Red: {
      main: '#EA1200',
      light: '#EE4133',
      dark: '#A30C00',
      contrastText: '#FFFFFF',
    },
    brandV2TreadGray3: {
      main: '#697982',
      light: '#a4adb2',
      dark: '#555E63',
      contrastText: 'white',
    },
    brandV2Yellow: {
      main: '#FFE500',
      light: '#FFEA33',
      dark: '#ECC600',
      contrastText: '#132732',
    },
  },
  typography: {
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    subtitle1Medium: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle1SemiBold: {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  components: {
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        //Veni add better switcher styles
        // Root: {
        //   Padding: 8,
        //   '& .MuiSwitch-track': {
        //     BorderRadius: 22 / 2,
        //
        //     '&:before, &:after': {
        //       Content: '""',
        //       Position: 'absolute',
        //       Top: '50%',
        //       Transform: 'translateY(-50%)',
        //       Width: 16,
        //       Height: 16,
        //     },
        //     '&:before': {
        //       BackgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        //         'primary',
        //       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        //       Left: 12,
        //     },
        //     '&:after': {
        //       BackgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        //         'primary',
        //       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        //       Right: 12,
        //     },
        //   },
        //   '&.MuiSwitch-thumb': {
        //     BoxShadow: 'none',
        //     Width: 16,
        //     Height: 16,
        //     Margin: 2,
        //   },
        // },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontSize: '12px',
          fontWeight: '600',
        },
        cellContent: {
          fontSize: '12px',
        },
      },
    },
    // Typography
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <subtitle1> by default
          subtitle1Medium: 'subtitle1',
          subtitle1SemiBold: 'subtitle1',
        },
      },

      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        h1: {
          fontSize: 88,
          fontWeight: 200,
        },
        h2: {
          fontSize: 54,
          fontWeight: 200,
        },
        h3: {
          fontSize: 44,
          fontWeight: 300,
        },
        h4: {
          fontSize: 32,
          fontWeight: 400,
        },
        h5: {
          fontSize: 22,
          fontWeight: 400,
        },
        h6: {
          fontSize: 18,
          fontWeight: 600,
        },
        body1: {
          fontSize: 14,
        },
        body2: {
          fontSize: 12,
        },
        overline: {
          fontSize: 10,
          textTransform: 'uppercase',
        },
        subtitle1: {
          fontSize: 14,
          fontWeight: 400,
        },
        subtitle2: {
          fontSize: 12,
          fontWeight: 600,
        },

        caption: {
          fontSize: 10,
          fontWeight: 400,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#FABD060D',
          },
        },
      },
    },
    // Buttons
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',

          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
          fontWeight: 400,
          fontSize: '14px',
          fontStyle: 'normal',
          height: '36px',

          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
        containedPrimary: {
          color: '#000000',
          boxShadow:
            '0px 3px 1px -2px #00000033; 0px 2px 2px 0px #00000024; 0px 1px 5px 0px #0000001F;',
        },
      },
    },

    // TextField
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: {
          marginTop: '2px',
          '& label.Mui-focused': {
            color: '#252135',
          },
          '& .MuiFormHelperText-root': {
            '&.Mui-error': {
              margin: '0px',
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px', // Setting border width to 2px when there is an error
            },

            '&:not(.Mui-error).required .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #F6CC46', // Apply this border only when 'required' and no 'error'
            },
          },

          '&.required': {
            '& .MuiOutlinedInput-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              border: `2px solid #F6CC46`,
            },
          },

          //Border style !! for outlined only
          // '& .MuiOutlinedInput-root': {
          //   '&.Mui-focused fieldset': {
          //     BorderColor: 'green',
          //   },
          // },
        },
      },
    },
    // Checkbox
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          lineHeight: '18px',
          '&.MuiChip-sizeMedium': {
            height: 26,
            width: 95,
          },
          '&.MuiChip-outlined': {
            backgroundColor: '#E0E0E0',
            '&.MuiChip-colorDefault': {
              backgroundColor: 'rgba(37, 33, 53, 0.04)',
              border: '1px solid rgba(37, 33, 53, 0.30)',
              color: '#1C1C1C',
            },
            '&.MuiChip-colorPrimary': {
              backgroundColor: '#FDFBEB',
              border: '1px solid #D9A64D',
              color: '#4B3525',
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: '#F5F5F5',
              border: '1px dashed #BDBDBD',
            },
            '&.MuiChip-colorError': {
              backgroundColor: 'rgba(251, 234, 234, 0.30)',
              border: '1px solid #DF6666',
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: 'rgba(2, 136, 209, 0.08)',
              border: '1px solid #0288D1',
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: 'rgba(46, 125, 50, 0.04)',
              border: '1px solid #2E7D32',
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: 'rgba(211, 47, 47, 0.10)',
            },
          },
        },
      },
    },
    // Radio
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },

    // Slider
    MuiSlider: {
      defaultProps: {
        color: 'primary',
      },
    },
    // Select
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {},
    },
    // Menu
    MuiMenu: {
      defaultProps: {
        // AnchorOrigin: {
        //   Vertical: 'bottom',
        //   Horizontal: 'left',
        // },
        // TransformOrigin: {
        //   Vertical: 'top',
        //   Horizontal: 'left',
        // },
        // Elevation: 1,
      },
      styleOverrides: {
        paper: {
          borderRadius: 4,
        },
      },
    },
    // Dialog
    MuiDialog: {
      defaultProps: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      styleOverrides: {
        paper: {
          borderRadius: 4,
        },
      },
    },
    //Tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            height: '2px',
            borderRadius: '6px 6px 0px 0px',
          },
        },
      },
    },
    // Tab
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 600,
          fontStyle: 'normal',
          '&.Mui-selected': {
            color: '#000000',
            '> svg': {
              fill: 'inherit',
            },
          },
        },
      },
    },
  },

  brandV2: {
    borderRadius: '6px',
    colors: {
      treadBlack: '#132732',
      treadBlue: '#0074DF',
      treadGray1: '#334752',
      treadGray2: '#4E606A',
      treadGray3: '#697982',
      treadGray4: '#84929A',
      treadGray6: '#BBC4C9',
      treadGray7: '#D6DDE1',
      treadGray8: '#F1F6F9',
      treadGray9: '#FAFAFA',
      treadGreen: '#00A11A',
      treadOrange: '#FFAA13',
      treadOrangeDark: '#FF7A00',
      treadRed: '#EA1200',
      treadYellow: '#FFE500',
      treadYellowDark: '#ECC600',
    },
  },
});

export default theme;
