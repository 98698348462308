import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { CommonLoader } from '~components/Loaders/CommonLoader';
import { getTokenProp } from '~constants/consts';
import { useAccess } from '~hooks/useAccess';
import { useCompany } from '~hooks/useCompany';
import { usePermissions } from '~hooks/usePermissions';
import { useUsers } from '~hooks/useUsers';
import { useStores } from '~store';

import { getRoutes } from './routeUtils';

export const Router = observer(() => {
  const { authStore, userStore } = useStores();
  const { getUser } = useUsers();
  const { getAccess } = useAccess();
  const { getPermissions } = usePermissions();
  const { getUserCompany } = useCompany();

  const isAuthenticated = authStore.isAuthenticated;
  const hasToken = Boolean(localStorage.getItem(getTokenProp));

  const [splitSDKConfig, setSplitSDKConfig] = useState<SplitIO.IBrowserSettings>();
  const [isPendingSplitSetup, setIsPendingSplitSetup] = useState(hasToken);

  const isDoneLoadingUser =
    !isPendingSplitSetup &&
    !_.isEmpty(userStore.user) &&
    !_.isEmpty(userStore.userAccess) &&
    !_.isEmpty(userStore.userPermissions);
  const isReady = hasToken ? isAuthenticated && isDoneLoadingUser : true;

  useEffect(() => {
    if (!hasToken) {
      return;
    }

    const token = localStorage.getItem(getTokenProp) as string;

    getUser().then(async (user) => {
      if (user) {
        // Reflect in the store that the token that was saved in the local storage
        // Was actually a valid token
        authStore.login(token);

        getAccess();
        getPermissions();

        getUserCompany(String(user.company?.id)).then((userCompany) => {
          // SplitIO setup
          const splitSDKKey = import.meta.env.TREAD__SPLITIO_SDK_API_KEY || '';
          // For local testing, feature flags can be set in:
          //    - env/.env.development: if you want all devs to see it
          //    - env/.env.local: if you want to test it locally
          const featureFlags = import.meta.env.TREAD__FEATURE_FLAGS
            ? JSON.parse(import.meta.env.TREAD__FEATURE_FLAGS)
            : {};

          if (splitSDKKey && userCompany.treadId) {
            const splitSDKConfig = {
              core: {
                authorizationKey: splitSDKKey,
                // Key is used to identify the user's company, since the trafficType is set to 'companies'
                key: userCompany.treadId,
                trafficType: 'companies',
              },
              features: featureFlags,
            };

            setSplitSDKConfig(splitSDKConfig);
            setIsPendingSplitSetup(false);
          } else {
            setIsPendingSplitSetup(false);
          }
        });
      } else {
        setIsPendingSplitSetup(false);
      }
    });
  }, [hasToken]);

  if (!isReady) {
    return <CommonLoader />;
  }

  const routes = getRoutes(userStore);

  return (
    <SplitFactoryProvider config={splitSDKConfig}>
      <RouterProvider router={createBrowserRouter(routes)} />
    </SplitFactoryProvider>
  );
});
